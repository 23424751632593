import React from "react";

import { Banner, InternationalTabs } from "../../reusable/organisms";
import { DefaultLayout } from "../../reusable/Templates";

import internationalBanner from "../../assets/images/international-support/internationalBanner.png";

const international = () => {
  return (
    // <DefaultLayout>
    //   <Banner
    //     image={internationalBanner}
    //     subtitle="Our initiatives of support to ECHO Hubs in other regions"
    //     title="International Support"
    //   />
    //   <InternationalTabs />
    // </DefaultLayout>
    // REMOVED TEMPORARILY FOR LEGAL REASONS
    <div></div>
  );
};

export default international;
